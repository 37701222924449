import React from 'react';
import { Question } from '../types';
import { BarChart2, Users, Tag } from 'lucide-react';

interface QuestionDisplayProps {
  question: Question;
  isLoading: boolean;
}

const QuestionDisplay: React.FC<QuestionDisplayProps> = ({ question, isLoading }) => {
  // Determine color based on difficulty
  const difficultyColor = {
    'Easy': 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200',
    'Medium': 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200',
    'Hard': 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
  }[question.difficulty] || 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200';

  // Determine color based on question type
  const typeColor = question.type === 'QUESTIONS_FOR_CONNECTION' 
    ? 'border-blue-300 dark:border-blue-700' 
    : 'border-purple-300 dark:border-purple-700';

  return (
    <div className="question-display w-full relative" role="region" aria-label="Question Display">
      {/* Card stack effect with subtle shadows */}
      <div className="absolute inset-x-0 mx-auto w-[98%] h-[98%] bg-white dark:bg-gray-800 rounded-lg shadow-sm -bottom-1 opacity-70"></div>
      <div className="absolute inset-x-0 mx-auto w-[99%] h-[99%] bg-white dark:bg-gray-800 rounded-lg shadow-sm -bottom-0.5 opacity-80"></div>
      
      {/* Main card with animation */}
      <div 
        className={`bg-white dark:bg-gray-800 rounded-lg p-8 mb-4 min-h-[250px] flex flex-col justify-center shadow-lg border-t-4 ${typeColor} relative z-10 transition-all duration-500 ease-in-out`}
        style={{ 
          opacity: isLoading ? 0.7 : 1,
          transform: isLoading ? 'scale(0.98)' : 'scale(1)'
        }}
        aria-live="polite"
      >
        {isLoading ? (
          <div className="animate-pulse space-y-4">
            <div className="bg-gray-300 dark:bg-gray-600 h-6 w-3/4 mx-auto rounded"></div>
            <div className="bg-gray-300 dark:bg-gray-600 h-6 w-1/2 mx-auto rounded"></div>
          </div>
        ) : (
          <>
            <p className="text-2xl md:text-3xl font-bold text-center mb-6 leading-relaxed">{question.text}</p>
            <div className="flex flex-wrap justify-center gap-3 text-sm">
              <div className={`flex items-center px-3 py-1 rounded-full ${difficultyColor}`}>
                <BarChart2 className="h-4 w-4 mr-1" />
                <span className="font-medium">{question.difficulty}</span>
              </div>
              <div className="flex items-center px-3 py-1 rounded-full bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200">
                <Users className="h-4 w-4 mr-1" />
                <span>{question.ageGroup}</span>
              </div>
              <div className="flex flex-wrap justify-center gap-1 mt-2 w-full">
                {question.categories.map(category => (
                  <span 
                    key={category}
                    className="inline-flex items-center px-2 py-1 rounded-md bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-200 text-xs"
                  >
                    <Tag className="h-3 w-3 mr-1" />
                    {category}
                  </span>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default QuestionDisplay;